<template>
  <main class="main page supporting resources CMS_pages">
    <div class="heroBanner">
      <img src="../assets/images/heroes/header-recycle.jpg">
      <h1 class="section__heading">Donating & Recycling</h1>
    </div>
    <section class="section sectionContiner" id="donating">
      <div class="text">
        <h1 class="section__heading">Donating Furniture</h1>
        <img src="@/assets/images/resources/donating.jpg">
        <p>Replacing your furniture can be a daunting task. You want to get rid of it as fast as possible and tossing your home furnishings to the curb is customarily the answer. But if your sofa, coffee table, dresser, credenza, etc. are in decent shape, we encourage folks to donate the items instead. Not only will you be helping the environment, but you’ll be also assisting people and families in need (and it’s tax deductible!). </p>

        <p>There are numerous national non-profits that accept – and often pick-up – furniture donations. For a list of organizations and some details about their services/protocols, check out <a href="https://www.mymove.com/moving/donations-and-removals/free-donation-pickup/" target="_blank">this website</a>.</p>

        <p>Prefer to donate to smaller charitable organizations? Ones that work within your community? We suggest contacting your local chamber of commerce for information. </p>
      </div>
    </section>

    <section class="section sectionContiner" id="recycling">
      <div class="text">
        <h1 class="section__heading">Recycling Furniture</h1>
        <img src="@/assets/images/resources/recycling.jpg">
        <p>If your old furniture isn’t in good enough shape to donate, recycling is an option. Due to the construction/materials of some furniture (i.e. a sofa that consists of wood, metal, foam and upholstery or leather), items may need to be broken down. While there’s a little leg work involved, it’s well worth it knowing you’re helping to keep our planet green! Here’s a link with <a href="https://info.junk-king.com/recycling-old-furniture-what-you-need-to-know" target="_blank">information and guidelines</a> to help.</p>
      </div>
    </section>

    <section class="section sectionContiner" id="handyman">
      <div class="text">
        <h1 class="section__heading">Handyman Help</h1>
        <img src="@/assets/images/resources/handyman.jpg">
        <p>Whether you need someone to help carry a sofa to the sidewalk (customarily, non-profits will only collect furniture curbside) or want someone more specialized to take apart your old dresser, <a href="https://www.taskrabbit.com/" target="_blank">TaskRabbit</a> might be your best answer.</p>
      </div>
    </section>

  </main>
</template>

<style lang="scss" scoped>
// .hero__image {
//   background-image: url('../assets/images/heroes/header-recycle.jpg');
//   background-position: center bottom;

//   @include breakpoint($l-up) {
//     background-position: center 85%;
//   }
// }
.text img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
</style>
